import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutEn = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      ludo: file(relativePath: { eq: "ludo.jpg" }) {
        childImageSharp {
          fixed(width: 260) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      lacuzon: file(relativePath: { eq: "lacuzon.jpg" }) {
        childImageSharp {
          fixed(width: 196) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  const [isFolded, setIsFolded] = useState(true)
  return (
    <Layout lang="en" location={location}>
      <SEO
        title="Who am I?"
        description="Ludovic Guyot, musical and tourist animator"
        lang="en"
      />
      <div className="columns is-centered">
        <div className="column is-3 has-text-centered pt-5">
          <Img
            fixed={data.ludo.childImageSharp.fixed}
            alt="Ludovic Guyot"
            style={{ maxWidth: 260, width: "100%", borderRadius: 20 }}
          />
        </div>
        <div className="column">
          <p>
            My name is Ludovic GUYOT and I was born in Lons-le-Saunier, in Jura.
          </p>
          <p>
            I have been living here since then and still discover my environment
            with pleasure thanks to my walks and to the pratice of geocaching.
          </p>
          <h3>My professionnal career</h3>
          <p>
            I’ve always been interested in History and I passed a history
            licence in Besançon.
          </p>
          <p>
            I started my professional career as a “Memory assistant” in the
            Veterans National Office, with the aim of creating links between the
            former and the new generations. I also had different experiences in
            Tourist office in Franche-Comté.
          </p>
          <h3>My tastes</h3>
          <p>
            I like to transmit what I know and I like to discover new things far
            the beaten tracks.
          </p>
          <p>
            I like to meet to meet new people, to discover new paths and new
            landscape. I like history and the heritage of my region. I like the
            good products of Jura and to share them, maybe with you…
          </p>
          <p>Looking forward to travelling and walking with you.</p>
        </div>
      </div>

      <div className="columns is-vcentered">
        <div className="column is-3 has-text-centered">
          <Img
            fixed={data.lacuzon.childImageSharp.fixed}
            alt="Lacuzon"
            style={{ maxWidth: 196, width: "100%" }}
          />
        </div>
        <div className="column">
          <h3>Why "Lacuzon?"</h3>
          <p>
            It was in 1674 that Franche-Comté (Jura, Doubs, Haute-Saône and
            Belfort territory) was attached to France, after 39 years of war of
            which Lacuzon was the hero.
          </p>
          <p>
            Claude Prost, dit Lacuzon, was born in Longchaumois in 1607 to
            farming parents and, not having the peasant vocation, he came down
            to Saint-Claude where he (perhaps?) practised the trade of
            shoemaker. Married to Jeanne Blanc on October 31, 1632 with whom he
            had 2 daughters, he seemed to lead a row of small craftsmen in the
            shadow of the famous Sanclaudian abbey.
          </p>
          <h4>1636: His destiny shifts</h4>
          <p>
            The course of his life changed with the war that Richelieu, then
            minister of Louis XIII, launched against the House of Austria to
            annex the Franche-Comté. When Dole was besieged by 18,000 men under
            the leadership of the Grand Condé, Claude Prost was mobilized with a
            troop of Sanclaudians and his ascendant over the soldiers would not
            stop growing. Hardy but cautious, he is soon nicknamed «La Cuson»,
            in patois «le souci».
          </p>
          {isFolded ? (
            <div className="fake-a" onClick={() => setIsFolded(false)} role="button" tabIndex={0} onKeyDown={() => setIsFolded(false)}>
              Read more...
            </div>
          ) : (
            <>
              <h4>1636-1642: An Abominable Period</h4>
              <p>
                Franche-Comté is almost totally occupied by the French aided by
                Swedish mercenaries. Only Dole, Salins, Besançon and Gray is
                tough. This is the most terrible time that one can imagine. The
                "greys" ravage, plunder, set fire to, kill, and commit the worst
                horrors against those who resist them. From more, famine and
                plague decimate the population and the country seems to become a
                desert. It was at this time that the quarry Lacuzon's military
                takes off. He leads a struggle implacable against the "greys" of
                Lespinassou in the Lespinassou region. Lons and Bletterans.
              </p>
            </>
          )}
        </div>
      </div>
      {isFolded ? null : (
        <>
          <h4>Lacuzon "takes a step forward".</h4>
          <p>
            In 1637, he joined "la terce", a regular unit of 1000 riders and
            2000 infantrymen commanded by Baron d'Arnans. This the latter then
            delegated to Lacuzon the custody of a large post of strategic
            importance of the access to the Biel valley in
            Vaux-les-Saint-Claude.
          </p>
          <p>
            But in 1639, the situation was most critical: the Comtois were
            submerged and now hold only a few strongholds in the especially in
            the mountainous area. They cannot prevent the bag and the
            Saint-Claude fire. However, the enemy quickly withdrew of these
            rough mountains of the Haut-Jura too difficult to hold in occupation
            and... Lacuzon takes control of the situation. Its role then
            finished, d'Arnans leaves the Franche-Comté, while doing grant
            Lacuzon the command of the Château de Montaigu.
          </p>
          <h4>The castle of Montaigu becomes his residence</h4>
          <p>
            1642: Lacuzon settled in the ruins of its new castle, a veritable
            eagle's nest overlooking Lons-le-Saunier and the occupied plains.
            Well restored, its fortress seems to be in a good state of repair.
            impregnable by the enemy, which earned him warm congratulations from
            the Governor and Parliament of Dole.
          </p>
          <p>
            For many years, until the conclusion of the peace treaty of the
            Pyrenees of November 1659 which confirms the membership of the
            County to the House of Spain, the situation remains confused. Some
            raids and helpings ravage especially the border regions; in 1644,
            Turenne seized Baume-les-Dames, Vesoul and Luxeuil.
          </p>
          <p>
            For Lacuzon, this will be a great period in his career. From
            Montaigu, where he settled with his wife and chaplain, he never
            ceased to harass enemy communications. At that time he seized of the
            fortified castle of Saint-Laurent la Roche which controls access of
            the Revermont and which had been occupied by the French since 1637.
            The the political repercussions of this stronghold in the country.
            Franche-Comté and, in 1642, the King of Spain officially granted to
            Lacuzon the captaincy and command of this castle.
          </p>
          <h4>A true lord</h4>
          <p>
            Having become much richer, our franc-comtois hero lives in the
            country. as it was then admitted at the time, plundering enemy
            convoys and releasing his noble prisoners against high ransoms...
          </p>
          <p>
            In 1643, at the age of 36 and after 7 years of war, he had an
            exceptional location. Having left as a single soldier, he is now
            governor of the castles of Montaigu, Saint-Laurent and Arlay. Sa
            presence behind the fortified walls reassures the population, which
            regains courage, but its military activities are more limited in
            addition to the repression of looting and abuses committed by gangs
            of deserted soldiers.
          </p>
          <p>
            On the other hand, his generosity remains intact and he sometimes
            shows it with ostentation, for example making a considerable
            donation of 500 F to Saint-Claude for the restoration of a chapel
            burnt down by the "Saint-Claude family". gray", adding 100 écus for
            a perpetual mass foundation . It was also at this time that he
            bought an important property where he had a beautiful house built
            and, in 1651, he moved to the settles permanently with his wife and
            two daughters.
          </p>
          <h4>Lacuzon is not always flawless...</h4>
          <p>
            During all these years, he reigns a little in oriental satrapy over
            his captaincies and, alas, it happens that he commits numerous acts
            of abuse... Naturally "gallant", he does not hesitate to abuse the
            women who resist it: in short, many women are created who resist it.
            enemies. Complaints are made against him in the Dole Parliament. and
            he is arrested, imprisoned for some time in the concierge's office.
            He was confiscated his castle in Saint-Laurent, his trial was
            instructed and his followed with passion and complaints, serious or
            futile, accumulate. But Lacuzon has devoted friends and one
            remembers in particular the services rendered...
          </p>
          <p>
            Finally, on 22 November 1659, he was absolved by the King of Spain,
            by the Dole Parliament and acclaimed by popular sentiment.
            Saint-Laurent la Roche was returned to him. And like the peace
            treaty of the Pyrenees has just been signed (on 7 November), the
            French evacuate the territories they occupy.
          </p>
          <h4>Peace will not be sustainable.</h4>
          <p>
            Louis XIV, King of France since 1643, did not renounce this province
            of Spain, badly governed by Queen Marie-Anne from Austria. It does
            nothing to strengthen the defences nor does it galvanise the
            patriotism of its inhabitants. Blunted by 24 years of exhausting
            struggles, the Franc-Comtois are more and more divided. A strong
            party of the nobility is leaning more and more towards the side of
            France and Lacuzon has difficulty in obtaining ammunition, soldiers
            and subsidies.
          </p>
          <p>
            In 1668, Condé entered Franche-Comté with 20,000 men and seized the
            land. of Besançon, Salins, Dole and Gray. All the authorities are
            ready to swear an oath to Louis XIV.
          </p>
          <p>
            Lacuzon is summoned by the new French governor to hand over its
            castle of St-Laurent to the royal troops and to come and do his
            "Château de St-Laurent". official submission". He takes the oath,
            returns to St-Claude, ready to pass through Switzerland if the
            French wanted to harm him. These the last ones destroy from top to
            bottom its castles of Montaigu and St-Laurent.
          </p>
          <h4>New play</h4>
          <p>
            On 2 May 1668, the new peace treaty signed in Aachen Franche-Comté
            to Spain, but it is not the only one. now a province divided between
            "resistants" and "resistance fighters". collaborators" that our
            franc-comtois hero must defend.
          </p>
          <p>
            The Court of Spain then sent a Flemish governor, the Prince of
            Spain. of Arenberg, who immediately called upon ...Lacuzon from whom
            he learned loyalty and ability. He entrusts him with the command of
            a lease of which Lons is the centre, with almost absolute powers.
          </p>
          <p>
            For six more years, Lacuzon will enjoy the confidence total and
            deserved from the Spanish governor. He strives to overcome the
            apathy, or even hostility, of the population, is trying to regain
            the struggles, and must overcome a certain Marquis de Listenois.
            This one, at the head of an important faction of the nobility
            comtoise, however, could not overcome Lacuzon, fleeing from the
            finally and takes refuge in France.
          </p>
          <h4>Last jolts of a war that has lasted too long.</h4>
          <p>
            1673: The war is not far away and the Sun King knows that he can now
            count on many supporters. He sends a considerable army and caused
            Besançon to capitulate. On 4 June 1674, the the town of Salins is
            also invested by the troops of the Duke of Salins. Luxembourg: it
            will be the final battle that will close the conquest of
            Franche-Comté. Lacuzon is desperately trying to but, under pressure
            from the notables of the Saline region, the truce is concluded.
          </p>
          <p>
            Franche-Comté is desperate, but it made the King pay dearly of
            France his final conquest, and his honour is safe...
          </p>
          <p>
            Lacuzon could have retired quietly to Montaigu. But, at 67 years
            old, he cannot resign himself to seeing the "fleur de lys" flag.
            float on its Haut-Jura and become the unarmed target of the grudges
            accumulated against him...
          </p>
          <p>
            He decides to take refuge in Milan, a Spanish citadel. He makes his
            to his family, which he leaves in Montaigu or Saint-Claude and draws
            up a will. He separates from Denise Gobet, "his young and She is a
            "grant governess" who serves as her "countrywoman". And, 9 months
            later, it will declare the illegitimate birth of a son. whose
            paternity she attributes to Lacuzon...
          </p>
          <p>
            In 1678, when he arrived in Milan, he resumed the struggle by
            leading a expedition against the rebellious Sicilians. And it is the
            17th of September of the same year that the Peace of Nijmegen
            consecrated the annexation of the Franche-Comté and restores peace
            between France and Spain: Lacuzon can freely return to his native
            country.
          </p>
          <h4>The last days of Claude Prost</h4>
          <p>
            Lacuzon, then aged 72, returned to the Jura at the end of 1679. His
            wife died there, along with Anne-Marie, his favourite daughter. From
            the attitude of his son-in-law, who has sincerely rallied to the
            France, seems to him to be a forfeit. He therefore refuses to stay
            in this Franche-Comté that he still considers unfairly occupied and
            decides to return to Milan.
          </p>
          <p>
            He stops in Longchaumois, his native village, and then leaves the
            Jura for good. He died in Milan on 21 December 1681, surrounded by
            his comrades in exile.
          </p>
          <p>
            Such was Captain Lacuzon's destiny. The legend is embellished it,
            deformed it, and the Lacuzon of the famous novel of cape and sword
            of Xavier de Montépin "The Doctor of the Poor" has not not much in
            common with the brave, loyal and unfortunate soldier that he really
            was. But Lacuzon is a name that resounds in Franche-Comté as the
            faithful echo of the famous motto :
          </p>
          <p>« COMTOIS RENDS-TOI : NENNI MA FOI »</p>
          <h4>Bibliography :</h4>
          <p>LACUSON de FONVILLE</p>
          <p>LACUSON de LAUTREY</p>
          <p>
            L'Histoire de la Terre et de l'Abbaye de Saint-CLaude de Don BENOIT
          </p>
        </>
      )}
    </Layout>
  )
}

export default AboutEn
